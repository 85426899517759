import React, { useCallback } from "react"
import PropTypes from "prop-types"
import uuid from "uuid-random"

import { StoreConsumer } from "../../store"

const FormEmbed = ({ id }) => {
  const containerRef = useCallback((el) => {
    if (!el) return
    el.id = `a${uuid()}`

    const create = () => {
      el.innerHTML = ``
      window.hbspt.forms.create({
        region: `na1`,
        portalId: `2759897`,
        formId: id,
        target: `#${el.id}`,
      })
    }

    if (window.hbspt) create()
    else {
      const script = document.createElement(`script`)
      script.onload = create
      script.defer = true
      script.src = `https://js.hsforms.net/forms/v2.js`
      document.head.appendChild(script)
    }
  }, [])

  return (
    <StoreConsumer>
      {({ translations }) => (
        <div ref={containerRef} className="form-embed-hubspot">
          {translations[`loading`]}…
        </div>
      )}
    </StoreConsumer>
  )
}

FormEmbed.propTypes = {
  id: PropTypes.string.isRequired,
}

export default FormEmbed
