import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Location } from "@reach/router"
import slugify from "slugify"

import FormEmbed from "../form-embed"
import Link from "../link"
import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import { StoreConsumer } from "../../store"

const Container = styled.article`
  .wp-video {
    width: 100% !important;

    video {
      width: 100% !important;
      height: 100% !important;
    }
  }

  footer {
    @media ${(props) => props.theme.xsmallDown} {
      color: ${(props) => props.theme.colorPurpleLight};
    }
  }
`

const Aside = styled.div`
  time {
    color: ${(props) => props.theme.colorPurpleLight};
  }

  a {
    &:hover {
      color: ${(props) => props.theme.colorPurpleLight};
    }
  }
`

const FormEmbedCta = styled.div.attrs({ className: `styled-p-leading` })`
  margin-bottom: 2rem;

  @media ${(props) => props.theme.xsmallDown} {
    margin-bottom: 1.6rem;
  }

  a {
    position: relative;
    display: flex;
    align-items: baseline;

    &:hover {
      opacity: 0.6;
    }

    &::before {
      content: "";
      width: 0.5em;
      height: 0.48em;
      margin-right: 1em;
      flex-shrink: 0;
      background-color: ${(props) => props.theme.colorRed};
      border-radius: 999em;
    }
  }
`

const FormEmbedContainer = styled.div`
  margin-top: 4rem;
  padding: 3rem 4rem;
  background-color: ${(props) => props.theme.colorGreyDark};

  @media ${(props) => props.theme.mediumDown} {
    padding: 1.8rem 1.2rem;
  }

  @media ${(props) => props.theme.xsmallDown} {
    margin-top: 2rem;
  }

  > h2 {
    margin-bottom: 2rem;
    color: ${(props) => props.theme.colorWhite};
  }
`

const Share = styled.footer`
  margin-top: 4rem;

  @media ${(props) => props.theme.xsmallDown} {
    margin-top: 2rem;
  }

  > h2 {
    margin-bottom: 2rem;
  }
`

const PostContent = ({ data: { content, date, categories, formEmbed } }) => {
  const formEmbedHtmlId =
    formEmbed &&
    formEmbed.acf.cta &&
    slugify(formEmbed.acf.cta, { lower: true })

  return (
    <StoreConsumer>
      {({ translations, metaDefaults }) => (
        <Location>
          {({ location }) => {
            const pageUrl = metaDefaults.items.siteUrl + location.pathname

            return (
              <Container>
                <Grid>
                  <Row pullY={1} mq={{ xsmallDown: { pullY: 2 } }}>
                    <Cell
                      as={Aside}
                      size={4}
                      mq={{
                        xsmallDown: { size: 19 },
                      }}
                    >
                      <p className="styled-p">
                        <time dateTime={date}>{date}</time>

                        {categories &&
                          categories.length > 0 &&
                          categories.map((category, i) => (
                            <React.Fragment key={i}>
                              <br />

                              <Link
                                to={`${translations[`blog_url`]}/${
                                  category.slug
                                }`}
                                title={translations[`blog_category`]}
                                dangerouslySetInnerHTML={{
                                  __html: category.name,
                                }}
                              />
                            </React.Fragment>
                          ))}
                      </p>
                    </Cell>

                    <Cell
                      size={13}
                      pushX={4}
                      mq={{
                        xlargeDown: { size: 16, pushX: 3 },
                        mediumDown: { size: 17, pushX: 2 },
                        xsmallDown: { size: 23, pushX: 0, pushY: 2 },
                      }}
                    >
                      {formEmbed && formEmbed.acf.id && formEmbed.acf.cta && (
                        <FormEmbedCta>
                          <a
                            href={`#${formEmbedHtmlId}`}
                            dangerouslySetInnerHTML={{
                              __html: formEmbed.acf.cta,
                            }}
                          />
                        </FormEmbedCta>
                      )}

                      <div
                        className="styled styled--large-type"
                        dangerouslySetInnerHTML={{ __html: content }}
                      />

                      {formEmbed && formEmbed.acf.id && (
                        <FormEmbedContainer id={formEmbedHtmlId}>
                          {formEmbed.acf.cta && (
                            <h2
                              className="styled-h3"
                              dangerouslySetInnerHTML={{
                                __html: formEmbed.acf.cta,
                              }}
                            />
                          )}

                          <FormEmbed id={formEmbed.acf.id} />
                        </FormEmbedContainer>
                      )}

                      <Share>
                        <h2
                          className="styled-p-leading"
                          dangerouslySetInnerHTML={{
                            __html: translations[`blog_share_title`],
                          }}
                        />

                        <div className="styled styled--large-type">
                          <ul>
                            <li>
                              <Link
                                target="_blank"
                                to={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`}
                                dangerouslySetInnerHTML={{
                                  __html: translations[`facebook`],
                                }}
                              />
                            </li>

                            <li>
                              <Link
                                target="_blank"
                                to={`https://twitter.com/intent/tweet/?url=${pageUrl}`}
                                dangerouslySetInnerHTML={{
                                  __html: translations[`twitter`],
                                }}
                              />
                            </li>

                            <li>
                              <Link
                                target="_blank"
                                to={`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`}
                                dangerouslySetInnerHTML={{
                                  __html: translations[`linkedin`],
                                }}
                              />
                            </li>
                          </ul>
                        </div>
                      </Share>
                    </Cell>
                  </Row>
                </Grid>
              </Container>
            )
          }}
        </Location>
      )}
    </StoreConsumer>
  )
}

PostContent.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PostContent
