import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Hero from "../components/hero"
import Blurbs from "../components/blurbs"
import Link from "../components/link"
import PostContent from "../components/page-blog/post-content"

const postsToBlurbs = (posts, currentId, translations) => {
  posts = { ...posts }
  posts.edges = posts.edges
    .filter(({ node }) => node.id !== currentId)
    .slice(0, 3)

  return {
    acf: {
      title: (
        <Link
          to={translations[`blog_url`]}
          dangerouslySetInnerHTML={{
            __html: translations[`blog_more`],
          }}
        />
      ),
      blurbs:
        posts.edges &&
        posts.edges.length &&
        posts.edges.map(({ node }) => ({
          title: node.title,
          text: node.acf.excerptText,
          linkUrl: `${translations[`blog_url`]}/${node.slug}`,
          linkText: translations[`read_more`],
          date: node.date,
          image: node.acf.excerptImage,
          categories:
            node.categories &&
            node.categories.length &&
            node.categories.map((category) => ({
              name: category.name,
              url: `${translations[`blog_url`]}/${category.slug}`,
            })),
        })),
    },
  }
}

const BlogPostPage = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpPost,
    hero,
    formEmbed,
    postsAll,
    wp,
    siteMessage,
  } = data
  const { translations, menus } = pageContext

  const blurbs = useMemo(
    () => postsToBlurbs(postsAll, wp.id, translations),
    [postsAll, wp.id, translations]
  )

  hero.acf.title = hero.acf.title || wp.title

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      siteMessage={siteMessage}
    >
      <MetaWp page={metaWpPost} global={metaWpGlobal} defaults={metaDefaults} />

      <Hero data={hero} />

      <PostContent
        data={{
          content: wp.content,
          date: wp.date,
          categories: wp.categories,
          formEmbed,
        }}
      />

      <Blurbs data={blurbs} />
    </Layout>
  )
}

export default BlogPostPage

BlogPostPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpPostFragment
    ...SiteMessageFragment

    ...HeroPostFragment
    ...FormEmbedPostFragment
    ...PostsAllFragment

    wp: wordpressPost(id: { eq: $id }) {
      id
      title
      content
      date(formatString: "YYYY-MM-DD")
      categories {
        name
        slug
      }
    }
  }
`
